import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import TheWashingtonPostLogo from "@images/img-min/inThePress-post.svg"
import CnnLogo from "@images/img-min/inThePress-cnn.svg"
import SfgateLogo from "@images/img-min/inThePress-sfgate.svg"
const InThePress = () => {
  return (
    <section className="in-the-press">
      <div className="container">
        <h2>In the press</h2>
        <div className="in-the-press__cards">
          <div className="in-the-press__card">
            <TheWashingtonPostLogo />
            <p>
              8 important — and overlooked — questions to ask before buying a
              home
            </p>
            <blockquote>
              “8 important — and overlooked — questions to ask before buying a
              home.”
            </blockquote>
          </div>
          <div className="in-the-press__card">
            <CnnLogo />
            <p>
              Climate change be damned. More Americans are moving to high-risk
              areas
            </p>
            <blockquote>
              “Climate change be damned. More Americans are moving to high-risk
              areas”
            </blockquote>
          </div>
          <div className="in-the-press__card">
            <SfgateLogo />
            <p>
              'A big risk': Data shows more people are moving into cities most
              at risk for fire and drought
            </p>
            <blockquote>
              “Fairweather advises caution when purchasing homes in areas with
              high climate risks. For one, insurance premiums may be incredibly
              high, if an insurance company will cover you at all.”
            </blockquote>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InThePress
