import React from "react"
import CardHazardPost from "@components/BlogPage/Posts/CardHazardPost"
import { Link } from "gatsby"

export default function StayUpToDate({ posts }) {
  return (
    <section id="stay-up-to-date" className="stay-up-to-date">
      <div className="container">
        <h3>Climate Insights</h3>
        <h2 className="stay-up-to-date-title">Stay up to date.</h2>
        <div className="stay-up-to-date-cards">
          {posts.map((post, idx) => (
            <CardHazardPost
              key={idx}
              title={post.node.title}
              author={post.node.author?.name}
              body={post.node.body}
              customImage={post.node.customImage}
              slug={post.node.slug?.current}
              categories={post.node.categories}
              publishedAt={post.node.publishedAt}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
