import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import readingTime from "reading-time"
import { navigate } from "gatsby"
import CTARightArrow from "@images/img-min/ctaRightArrow.svg"

export default function CardHazardPost({
  title,
  author,
  body,
  customImage,
  slug,
  publishedAt,
  categories,
  customCardImage,
}) {
  const shorDescription =
    `${body[0]?.children[0]?.text} ${body[1]?.children[0]?.text} ${body[2]?.children[0]?.text}`.substring(
      0,
      120
    ) + "..."
  // let totalText = ""
  // for (const p of body) {
  //   totalText += p.children[0]?.text
  // }
  // const timeToRead = Math.ceil(readingTime(totalText).minutes)

  const getCategoryColor = category => {
    switch (category) {
      case "Heat":
        return "#FF6E05"
        break
      case "Storm":
        return "#9295E2"
        break
      case "Drought":
        return "#CAA65F"
        break
      case "Fire":
        return "#FFAA2C"
        break
      case "Flood":
        return "#96CDFF"
        break

      default:
        return "#242424"
        break
    }
  }

  const getDate = date => {
    const newDate = new Date(date)
    const americanDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }).format(newDate)

    return americanDate
  }

  return (
    <div className="card-hazard card-hazard-post-container">
      <div
        className="inner-card"
        onClick={() => {
          navigate(`/blog/${slug}`)
        }}
      >
        <div className="image-container">
          <div className="image-wrapper">
            <a href={`/blog/${slug}`}>
              {customCardImage ? (
                <GatsbyImage
                  image={customCardImage?.image.asset.gatsbyImageData}
                  alt=""
                />
              ) : (
                <GatsbyImage
                  image={customImage?.image.asset.gatsbyImageData}
                  alt=""
                />
              )}
            </a>
          </div>
        </div>
        <div className="content-container">
          <a href={`/blog/${slug}`}>
            <h2 className="title">{title}</h2>
          </a>
          <div className="categories">
            {categories.map((category, idx) => (
              <span key={idx} className="category">
                {category.title}
              </span>
            ))}
          </div>
          <div className="info">
            <span>
              {author} {"|"} {getDate(publishedAt)}
            </span>
          </div>
          {/* <div className="description">{shorDescription}</div> */}
          <div className="cta">
            Read more
            <CTARightArrow />
          </div>
        </div>
      </div>
    </div>
  )
}
